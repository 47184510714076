<style scoped>
.image-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #3d3dc1;
}
</style>
<template>
  <div v-if="fileDetail.imageUrl || defaultPhoto" class="image-preview mb-2">
    <div class="card border border-info">
      <div class="card-body text-center">
        <img :src="fileDetail.imageUrl ? fileDetail.imageUrl : defaultPhoto" alt="Image"
             :style="[{'max-height':imageHeight,'max-width':'100%'}]">
        <p v-if="fileSelected" class="image-caption">
          {{ fileDetail.name }} <br>
          ({{ fileDetail.size }} KB)
        </p>
      </div>
    </div>
  </div>
  <div class="input-group">
    <input
        type="text"
        :title="fileDetail.name"
        @click="selectFile" readonly
        class="form-control"
        :placeholder="fileDetail.name ? fileDetail.name : 'Select file...'"
    >
    <button v-if="fileSelected" @click="resetFile" class="btn btn-outline-danger" type="button">
      <i class="bi bi-trash"></i> Remove
    </button>
    <button @click="selectFile" class="btn btn-primary" type="button">
      <i class="bi bi-folder2-open"> Browse..</i>
    </button>
  </div>
  <input
      type="file" ref="file_element"
      @change="onFileSelected"
      class="form-control" hidden
      v-bind:class="[{'is-invalid':error.$error}]"
      :id="id"
  >
  <div v-if="error.$error" class="invalid-feedback">
    {{ error.$errors[0].$message }}
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  props: {
    modelValue: {},
    id: {
      type: String
    },
    defaultPhoto: {
      type: String
    },
    error: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    },
    imageHeight: {
      default: '150px'
    }
  },
  setup(props, {emit}) {
    const file_element = ref('')
    const file = ref('')
    const imageExtensions = ['image/jpeg', 'image/png', 'image/jpeg', 'image/gif']
    const fileSelected = ref(false)
    const fileDetail = ref({
      name: '',
      imageUrl: '',
      size: ''
    })

    function selectFile() {
      file_element.value.click()
    }

    function onFileSelected(event) {
      if (event.target.files.length === 0) {
        file.value = ''
      }
      file.value = event.target.files[0]
      if (!(file.value instanceof File)) {
        return;
      }
      fileSelected.value = true
      fileDetail.value.name = file.value.name
      fileDetail.value.size = (file.value.size / 1000).toFixed(2)
      emit('update:modelValue', file.value)

      if (imageExtensions.includes(file.value['type'])) {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(file.value);

        fileReader.addEventListener("load", () => {
          fileDetail.value.imageUrl = fileReader.result
        })
      }
    }

    function resetFile() {
      file.value = ''
      emit('update:modelValue', '')
      Object.assign(fileDetail.value, {
        name: '',
        imageUrl: '',
        size: ''
      })
      fileSelected.value = false
    }

    return {
      file_element,
      selectFile,
      resetFile,
      fileSelected,
      fileDetail,
      onFileSelected
    }
  }
}
</script>