import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const warehouseUrl = 'inventory/warehouse'

export default {
    namespaced: true,
    state: {
        warehouses: {
            data: [],
            loading: false
        },
        warehouse: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getWarehouseCode(){
            return axiosAdmin.get(`${warehouseUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getWarehouses({state, commit}) {
            if (!state.warehouses.data.length) {
                commit('SET_WAREHOUSES_LOADING', true)
                return axiosAdmin.get(`${warehouseUrl}`)
                    .then((res) => {
                        commit("SET_WAREHOUSES_LOADING", false);
                        commit('SET_WAREHOUSES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_WAREHOUSES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeWarehouse({commit}, form) {
            return axiosAdmin.post(`${warehouseUrl}`, form)
                .then((res) => {
                    commit('ADD_WAREHOUSE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getWarehouse({commit}, id) {
            commit('SET_WAREHOUSE_LOADING', true)
            return axiosAdmin.get(`${warehouseUrl}/${id}`)
                .then((res) => {
                    commit('SET_WAREHOUSE_LOADING', false)
                    commit('SET_WAREHOUSE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_WAREHOUSE_LOADING', false)
                    showErrors(err)
                });
        },
        updateWarehouse({commit}, {id, form}) {
            return axiosAdmin.put(`${warehouseUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_WAREHOUSE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteWarehouse({commit}, id) {
            return axiosAdmin.delete(`${warehouseUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_WAREHOUSE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_WAREHOUSES_LOADING: (state, loading) => {
            state.warehouses.loading = loading
        },
        SET_WAREHOUSES: (state, data) => {
            state.warehouses.data = data
        },
        ADD_WAREHOUSE: (state, data) => {
            state.warehouses.data.push(data)
        },
        SET_WAREHOUSE_LOADING: (state, loading) => {
            state.warehouse.loading = loading
        },
        SET_WAREHOUSE: (state, data) => {
            state.warehouse.data = data
        },
        UPDATE_WAREHOUSE: (state, {id, data}) => {
            state.warehouses.data[state.warehouses.data.findIndex(warehouse => warehouse.id === id)] = data
        },
        REMOVE_WAREHOUSE: (state, id) => {
            state.warehouses.data = state.warehouses.data.filter(data => data.id !== id)
        }
    }
}
