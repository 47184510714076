import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const productUrl = 'inventory/product'

export default {
    namespaced: true,
    state: {
        allProducts: {
            data: [],
            loading: false
        },
        products: {
            data: [],
            meta: {},
            loading: false
        },
        product: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getProductCode() {
            return axiosAdmin.get(`${productUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getAllProducts({commit}, {search = '', product_category_id = '', brand_id = '', sku = '', barcode = ''}) {
            commit('SET_ALL_PRODUCTS_LOADING', true)
            return axiosAdmin.get(`${productUrl}/all?search=${search}&product_category_id=${product_category_id}&brand_id=${brand_id}&sku=${sku}&barcode=${barcode}`)
                .then((res) => {
                    commit("SET_ALL_PRODUCTS_LOADING", false);
                    commit('SET_ALL_PRODUCTS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_PRODUCTS_LOADING", false);
                    showErrors(err)
                });
        },
        getProducts({commit}, {
            page = 1,
            limit = '',
            search = '',
            product_category_id = '',
            brand_id = '',
            sku = '',
            barcode = ''
        }) {
            commit('SET_PRODUCTS_LOADING', true)
            return axiosAdmin.get(`${productUrl}?page=${page}&limit=${limit}&search=${search}&product_category_id=${product_category_id}&brand_id=${brand_id}&sku=${sku}&barcode=${barcode}`)
                .then((res) => {
                    commit("SET_PRODUCTS_LOADING", false);
                    commit('SET_PRODUCTS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PRODUCTS_LOADING", false);
                    showErrors(err)
                });
        },
        storeProduct({commit}, form) {
            return axiosAdmin.post(`${productUrl}`, form)
                .then((res) => {
                    commit('ADD_PRODUCT', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },

        importProduct({dispatch}, form) {
            return axiosAdmin.post(`${productUrl}/import`, form)
                .then((res) => {
                    dispatch('getProducts', {})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getProduct({commit}, id) {
            commit('SET_PRODUCT_LOADING', true)
            return axiosAdmin.get(`${productUrl}/${id}`)
                .then((res) => {
                    commit('SET_PRODUCT_LOADING', false)
                    commit('SET_PRODUCT', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_PRODUCT_LOADING', false)
                    showErrors(err)
                });
        },
        updateProduct({commit}, {id, form}) {
            return axiosAdmin.post(`${productUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_PRODUCT', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteProduct({commit}, id) {
            return axiosAdmin.delete(`${productUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_PRODUCT', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ALL_PRODUCTS_LOADING: (state, loading) => {
            state.allProducts.loading = loading
        },
        SET_ALL_PRODUCTS: (state, data) => {
            state.allProducts.data = data
        },
        SET_PRODUCTS_LOADING: (state, loading) => {
            state.products.loading = loading
        },
        SET_PRODUCTS: (state, data) => {
            state.products.data = data.data
            state.products.meta = data.meta
        },
        ADD_PRODUCT: (state, data) => {
            state.products.data.push(data)
        },
        SET_PRODUCT_LOADING: (state, loading) => {
            state.product.loading = loading
        },
        SET_PRODUCT: (state, data) => {
            state.product.data = data
        },
        UPDATE_PRODUCT: (state, {id, data}) => {
            state.products.data[state.products.data.findIndex(product => product.id === id)] = data
        },
        REMOVE_PRODUCT: (state, id) => {
            state.products.data = state.products.data.filter(data => data.id !== id)
        }
    }
}
