import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const companyUrl = 'crm/company'

export default {
    namespaced: true,
    state: {
        companies: {
            data: [],
            loading: false
        },
        company: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getCompanyCode(){
            return axiosAdmin.get(`${companyUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getCompanies({state, commit}) {
            if (!state.companies.data.length) {
                commit('SET_COMPANIES_LOADING', true)
                return axiosAdmin.get(`${companyUrl}`)
                    .then((res) => {
                        commit("SET_COMPANIES_LOADING", false);
                        commit('SET_COMPANIES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_COMPANIES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeCompany({commit}, form) {
            return axiosAdmin.post(`${companyUrl}`, form)
                .then((res) => {
                    commit('ADD_COMPANY', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getCompany({commit}, id) {
            commit('SET_COMPANY_LOADING', true)
            return axiosAdmin.get(`${companyUrl}/${id}`)
                .then((res) => {
                    commit('SET_COMPANY_LOADING', false)
                    commit('SET_COMPANY', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_COMPANY_LOADING', false)
                    showErrors(err)
                });
        },
        updateCompany({commit}, {id, form}) {
            return axiosAdmin.post(`${companyUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_COMPANY', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteCompany({commit}, id) {
            return axiosAdmin.delete(`${companyUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_COMPANY', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_COMPANIES_LOADING: (state, loading) => {
            state.companies.loading = loading
        },
        SET_COMPANIES: (state, data) => {
            state.companies.data = data
        },
        ADD_COMPANY: (state, data) => {
            state.companies.data.push(data)
        },
        SET_COMPANY_LOADING: (state, loading) => {
            state.company.loading = loading
        },
        SET_COMPANY: (state, data) => {
            state.company.data = data
        },
        UPDATE_COMPANY: (state, {id, data}) => {
            state.companies.data[state.companies.data.findIndex(company => company.id === id)] = data
        },
        REMOVE_COMPANY: (state, id) => {
            state.companies.data = state.companies.data.filter(data => data.id !== id)
        }
    }
}
