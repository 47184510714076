<template>
  <label :for="id" class="form-label">{{ label }}</label>
  <ckeditor
      :editor="editor"
      :config="editorConfig"
      :value="modelValue"
      @input="$emit('update:modelValue',$event)"
  />
  <div v-if="error.$error" class="invalid-feedback">
    {{ error.$errors[0].$message }}
  </div>
</template>

<style>
.ck-content { height:200px; }
</style>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CkEditor from '@ckeditor/ckeditor5-vue';

export default {
  components:{
    ckeditor: CkEditor.component
  },
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    error: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default:{}
    },
    modelValue: {
      required: true,
    }
  },
  setup() {

    return {
      editor: ClassicEditor,
      editorConfig: {
        height: '800px'
      },
    }
  }
}
</script>