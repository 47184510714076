import {createStore} from "vuex";
import {axiosAdmin, axiosFront} from "@/helpers/axios";
import userModule from '@/store/modules/userManagement/user';
import roleModule from '@/store/modules/userManagement/role'
import fiscalYearModule from '@/store/modules/setting/fiscalYear'
import monthModule from "@/store/modules/setting/month"
import companySettingModule from '@/store/modules/setting/companySetting'
import accountSettingModule from '@/store/modules/setting/accountSetting'
import ledgerGroupModule from "@/store/modules/account/ledgerGroup";
import ledgerModule from "@/store/modules/account/ledger"
import clientGroupModule from "@/store/modules/crm/clientGroup"
import companyModule from "@/store/modules/crm/company"
import clientModule from "@/store/modules/crm/client"
import supplierModule from "@/store/modules/crm/supplier";
import unitModule from "@/store/modules/inventory/unit"
import warehouseModule from "@/store/modules/inventory/warehouse"
import brandModule from "@/store/modules/inventory/brand"
import taxModule from "@/store/modules/setting/tax"
import productCategoryModule from "@/store/modules/inventory/productCategory"
import productModule from "@/store/modules/inventory/product"
import departmentModule from "@/store/modules/humanResource/department"
import designationModule from "@/store/modules/humanResource/designation"
import employeeModule from "@/store/modules/humanResource/employee";
import purchaseModule from "@/store/modules/inventory/purchase"
import saleModule from "@/store/modules/inventory/sale"
import payHeadModule from "@/store/modules/humanResource/payHead"
import leaveTypeModule from "@/store/modules/humanResource/leaveType";
import leaveModule from "@/store/modules/humanResource/leave";
import employeeAttendanceModule from "@/store/modules/humanResource/employeeAttendance";
import employeeSalaryModule from "@/store/modules/humanResource/employeeSalary"
import payableChargeModule from "@/store/modules/humanResource/payableCharge"
import salaryPaymentModule from "@/store/modules/humanResource/salaryPayment"
import advanceSalaryModule from "@/store/modules/humanResource/advanceSalary"
import journalVoucherModule from "@/store/modules/account/journalVoucher";
import showErrors from "@/helpers/showErrors";
import paymentVoucherModule from "@/store/modules/account/paymentVoucher";
import receiptVoucherModule from "@/store/modules/account/receiptVoucher";
import bankModule from "@/store/modules/setting/bank"
import bankAccountModule from "@/store/modules/account/bankAccount";
import accountReportModule from "@/store/modules/account/accountReport"
import accountOpeningBalanceModule from "@/store/modules/account/accountOpeningBalance";
import inventoryReportModule from "@/store/modules/inventory/inventoryReport"
import purchaseReturnModule from "@/store/modules/inventory/purchaseReturn"
import paymentRecordModule from "@/store/modules/inventory/paymentRecord"
import receiptRecordModule from "@/store/modules/inventory/receiptRecord"
import productOpeningModule from "@/store/modules/inventory/productOpening";
import salesReturnModule from "@/store/modules/inventory/salesReturn"
import stockAdjustmentModule from "@/store/modules/inventory/stockAdjustment"
import quotationModule from "@/store/modules/inventory/quotation"
import smsTemplateModule from "@/store/modules/sms/smsTemplate"
import smsModule from  "@/store/modules/sms/sms"

const store = createStore({
    state: {
        user: {
            access_token: localStorage.getItem("access_token"),
            permissions: localStorage.getItem("permissions") ? localStorage.getItem('permissions').split(',') : []
        },
        dashboard: {
            data: {},
            loading: false
        },
        profile: {
            data: {}
        },
    },
    getters: {},
    actions: {
        login({commit}, user) {
            return axiosFront.post('login', user)
                .then(({data}) => {
                    commit('SET_TOKEN', data.access_token)
                    commit('SET_USER_PERMISSIONS', data.permissions)
                    return data;
                })
        },
        logout({commit}) {
            return axiosAdmin.post('logout')
                .then((res) => {
                    commit('LOGOUT')
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
        getProfile({state, commit}) {
            if (!Object.keys(state.profile.data).length) {
                return axiosAdmin.get('profile')
                    .then((res) => {
                        commit('SET_PROFILE', res.data.data)
                        return res
                    }).catch((err) => {
                        throw err;
                    });
            }
        },
        updateProfile({commit}, data) {
            return axiosAdmin.post(`profile/updateProfile`, data)
                .then((res) => {
                    commit('SET_PROFILE', res.data.data)
                    return res
                })
                .catch((err) => {
                    throw err;
                });
        },
        // eslint-disable-next-line no-unused-vars
        updatePassword({commit}, data) {
            return axiosAdmin.put(`profile/updatePassword`, data)
                .then((res) => {
                    return res
                }).catch((err) => {
                    throw err;
                })
        },
        getDashboardData({commit}) {
            commit("SET_DASHBOARD_LOADING",true)
            return axiosAdmin.get(`dashboard`)
                .then((res) => {
                    commit("SET_DASHBOARD_LOADING",false)
                    commit("SET_DASHBOARD_DATA",res.data)
                    return res
                }).catch((err) => {
                    commit("SET_DASHBOARD_LOADING",false)
                    showErrors(err)
                })
        }
    },
    mutations: {
        LOGOUT: (state) => {
            state.user.access_token = null;
            state.user.data = {};
            state.user.permissions = [];
            state.profile.data = {};
            localStorage.removeItem("access_token");
            localStorage.removeItem("permissions")
        },
        SET_TOKEN: (state, token) => {
            state.user.access_token = token;
            localStorage.setItem('access_token', token);
        },
        SET_PROFILE: (state, data) => {
            state.profile.data = data
        },
        SET_USER_PERMISSIONS: (state, permissions) => {
            state.user.permissions = permissions
            localStorage.setItem('permissions', permissions)
        },
        SET_DASHBOARD_LOADING: (state, loading) => {
            state.dashboard.loading = loading
        },
        SET_DASHBOARD_DATA: (state, data) => {
            state.dashboard.data = data
        }
    },
    modules: {
        userModule,
        roleModule,
        fiscalYearModule,
        monthModule,
        companySettingModule,
        accountSettingModule,
        ledgerGroupModule,
        ledgerModule,
        clientGroupModule,
        companyModule,
        clientModule,
        supplierModule,
        unitModule,
        warehouseModule,
        brandModule,
        taxModule,
        productCategoryModule,
        productModule,
        designationModule,
        departmentModule,
        employeeModule,
        purchaseModule,
        saleModule,
        stockAdjustmentModule,
        leaveTypeModule,
        leaveModule,
        payHeadModule,
        employeeAttendanceModule,
        employeeSalaryModule,
        payableChargeModule,
        salaryPaymentModule,
        advanceSalaryModule,
        journalVoucherModule,
        paymentVoucherModule,
        receiptVoucherModule,
        bankModule,
        bankAccountModule,
        accountReportModule,
        accountOpeningBalanceModule,
        inventoryReportModule,
        purchaseReturnModule,
        productOpeningModule,
        salesReturnModule,
        paymentRecordModule,
        receiptRecordModule,
        quotationModule,
        smsTemplateModule,
        smsModule
    }
});

export default store;
