import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const bankAccountUrl = 'account/bankAccount'

export default {
    namespaced: true,
    state: {
        bankAccounts: {
            data: [],
            loading: false
        },
        bankAccount: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getBankAccountCode() {
            return axiosAdmin.get(`${bankAccountUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getBankAccounts({state, commit}) {
            if (!state.bankAccounts.data.length) {
                commit('SET_BANK_ACCOUNTS_LOADING', true)
                return axiosAdmin.get(`${bankAccountUrl}`)
                    .then((res) => {
                        commit("SET_BANK_ACCOUNTS_LOADING", false);
                        commit('SET_BANK_ACCOUNTS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_BANK_ACCOUNTS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeBankAccount({commit}, form) {
            return axiosAdmin.post(`${bankAccountUrl}`, form)
                .then((res) => {
                    commit('ADD_BANK_ACCOUNT', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getBankAccount({commit}, id) {
            commit('SET_BANK_ACCOUNT_LOADING', true)
            return axiosAdmin.get(`${bankAccountUrl}/${id}`)
                .then((res) => {
                    commit('SET_BANK_ACCOUNT_LOADING', false)
                    commit('SET_BANK_ACCOUNT', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_BANK_ACCOUNT_LOADING', false)
                    showErrors(err)
                });
        },
        updateBankAccount({commit}, {id, form}) {
            return axiosAdmin.put(`${bankAccountUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_BANK_ACCOUNT', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteBankAccount({commit}, id) {
            return axiosAdmin.delete(`${bankAccountUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_BANK_ACCOUNT', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_BANK_ACCOUNTS_LOADING: (state, loading) => {
            state.bankAccounts.loading = loading
        },
        SET_BANK_ACCOUNTS: (state, data) => {
            state.bankAccounts.data = data
        },
        ADD_BANK_ACCOUNT: (state, data) => {
            state.bankAccounts.data.push(data)
        },
        SET_BANK_ACCOUNT_LOADING: (state, loading) => {
            state.bankAccount.loading = loading
        },
        SET_BANK_ACCOUNT: (state, data) => {
            state.bankAccount.data = data
        },
        UPDATE_BANK_ACCOUNT: (state, {id, data}) => {
            state.bankAccounts.data[state.bankAccounts.data.findIndex(bankAccount => bankAccount.id === id)] = data
        },
        REMOVE_BANK_ACCOUNT: (state, id) => {
            state.bankAccounts.data = state.bankAccounts.data.filter(data => data.id !== id)
        }
    }
}
