import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const advanceSalaryUrl = 'hr/advanceSalary'

export default {
    namespaced: true,
    state: {
        advanceSalaries: {
            data: [],
            loading: false
        },
        advanceSalary: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getAdvanceSalaries({commit}) {
            commit('SET_ADVANCE_SALARIES_LOADING', true)
            return axiosAdmin.get(`${advanceSalaryUrl}`)
                .then((res) => {
                    commit("SET_ADVANCE_SALARIES_LOADING", false);
                    commit('SET_ADVANCE_SALARIES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ADVANCE_SALARIES_LOADING", false);
                    showErrors(err)
                });
        },
        storeAdvanceSalary({commit}, form) {
            return axiosAdmin.post(`${advanceSalaryUrl}`, form)
                .then((res) => {
                    commit('ADD_ADVANCE_SALARY', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getAdvanceSalary({commit}, id) {
            commit('SET_ADVANCE_SALARY_LOADING', true)
            return axiosAdmin.get(`${advanceSalaryUrl}/${id}`)
                .then((res) => {
                    commit('SET_ADVANCE_SALARY_LOADING', false)
                    commit('SET_ADVANCE_SALARY', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_ADVANCE_SALARY_LOADING', false)
                    showErrors(err)
                });
        },
        updateAdvanceSalary({commit}, {id, form}) {
            return axiosAdmin.put(`${advanceSalaryUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_ADVANCE_SALARY', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteAdvanceSalary({commit}, id) {
            return axiosAdmin.delete(`${advanceSalaryUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_ADVANCE_SALARY', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ADVANCE_SALARIES_LOADING: (state, loading) => {
            state.advanceSalaries.loading = loading
        },
        SET_ADVANCE_SALARIES: (state, data) => {
            state.advanceSalaries.data = data
        },
        ADD_ADVANCE_SALARY: (state, data) => {
            state.advanceSalaries.data.push(data)
        },
        SET_ADVANCE_SALARY_LOADING: (state, loading) => {
            state.advanceSalary.loading = loading
        },
        SET_ADVANCE_SALARY: (state, data) => {
            state.advanceSalary.data = data
        },
        UPDATE_ADVANCE_SALARY: (state, {id, data}) => {
            state.advanceSalaries.data[state.advanceSalaries.data.findIndex(advanceSalary => advanceSalary.id === id)] = data
        },
        REMOVE_ADVANCE_SALARY: (state, id) => {
            state.advanceSalaries.data = state.advanceSalaries.data.filter(data => data.id !== id)
        }
    }
}
