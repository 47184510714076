import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const payHeadUrl = 'hr/payHead'

export default {
    namespaced: true,
    state: {
        payHeads: {
            data: [],
            loading: false
        },
        payHead: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getPayHeads({state, commit}) {
            if (!state.payHeads.data.length) {
                commit('SET_PAY_HEADS_LOADING', true)
                return axiosAdmin.get(`${payHeadUrl}`)
                    .then((res) => {
                        commit("SET_PAY_HEADS_LOADING", false);
                        commit('SET_PAY_HEADS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_PAY_HEADS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storePayHead({commit}, form) {
            return axiosAdmin.post(`${payHeadUrl}`, form)
                .then((res) => {
                    commit('ADD_PAY_HEAD', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getPayHead({commit}, id) {
            commit('SET_PAY_HEAD_LOADING', true)
            return axiosAdmin.get(`${payHeadUrl}/${id}`)
                .then((res) => {
                    commit('SET_PAY_HEAD_LOADING', false)
                    commit('SET_PAY_HEAD', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_PAY_HEAD_LOADING', false)
                    showErrors(err)
                });
        },
        updatePayHead({commit}, {id, form}) {
            return axiosAdmin.put(`${payHeadUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_PAY_HEAD', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deletePayHead({commit}, id) {
            return axiosAdmin.delete(`${payHeadUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_PAY_HEAD', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_PAY_HEADS_LOADING: (state, loading) => {
            state.payHeads.loading = loading
        },
        SET_PAY_HEADS: (state, data) => {
            state.payHeads.data = data
        },
        ADD_PAY_HEAD: (state, data) => {
            state.payHeads.data.push(data)
        },
        SET_PAY_HEAD_LOADING: (state, loading) => {
            state.payHead.loading = loading
        },
        SET_PAY_HEAD: (state, data) => {
            state.payHead.data = data
        },
        UPDATE_PAY_HEAD: (state, {id, data}) => {
            state.payHeads.data[state.payHeads.data.findIndex(payHead => payHead.id === id)] = data
        },
        REMOVE_PAY_HEAD: (state, id) => {
            state.payHeads.data = state.payHeads.data.filter(data => data.id !== id)
        }
    }
}
