import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const employeeUrl = 'hr/employee'

export default {
    namespaced: true,
    state: {
        salaryLists: {
            data: [],
            loading: false
        },
        employeeSalaries: {
            data: [],
            loading: false
        },
        employeeSalary: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getSalaryLists({commit}) {
            commit('SET_SALARY_LISTS_LOADING', true)
            return axiosAdmin.get(`${employeeUrl}/salary/latest`)
                .then((res) => {
                    commit("SET_SALARY_LISTS_LOADING", false);
                    commit('SET_SALARY_LISTS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_SALARY_LISTS_LOADING", false);
                    showErrors(err)
                });
        },
        getEmployeeSalaries({commit}, employee_id) {
            commit('SET_EMPLOYEE_SALARIES_LOADING', true)
            return axiosAdmin.get(`${employeeUrl}/${employee_id}/employeeSalary`)
                .then((res) => {
                    commit("SET_EMPLOYEE_SALARIES_LOADING", false);
                    commit('SET_EMPLOYEE_SALARIES', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_EMPLOYEE_SALARIES_LOADING", false);
                    showErrors(err)
                });
        },
        storeEmployeeSalary({commit}, {employee_id, form}) {
            return axiosAdmin.post(`${employeeUrl}/${employee_id}/employeeSalary`, form)
                .then((res) => {
                    commit('ADD_EMPLOYEE_SALARY', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getEmployeeSalary({commit}, {employee_id, id}) {
            commit('SET_EMPLOYEE_SALARY_LOADING', true)
            return axiosAdmin.get(`${employeeUrl}/${employee_id}/employeeSalary/${id}`)
                .then((res) => {
                    commit('SET_EMPLOYEE_SALARY_LOADING', false)
                    commit('SET_EMPLOYEE_SALARY', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_EMPLOYEE_SALARY_LOADING', false)
                    showErrors(err)
                });
        },
        updateEmployeeSalary({commit}, {employee_id, id, form}) {
            return axiosAdmin.put(`${employeeUrl}/${employee_id}/employeeSalary/${id}`, form)
                .then((res) => {
                    commit('UPDATE_EMPLOYEE_SALARY', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteEmployeeSalary({commit}, {employee_id, id}) {
            return axiosAdmin.delete(`${employeeUrl}/${employee_id}/employeeSalary/${id}`)
                .then((res) => {
                    commit('REMOVE_EMPLOYEE_SALARY', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_SALARY_LISTS_LOADING: (state, loading) => {
            state.salaryLists.loading = loading
        },
        SET_SALARY_LISTS: (state, data) => {
            state.salaryLists.data = data
        },
        SET_EMPLOYEE_SALARIES_LOADING: (state, loading) => {
            state.employeeSalaries.loading = loading
        },
        SET_EMPLOYEE_SALARIES: (state, data) => {
            state.employeeSalaries.data = data
        },
        ADD_EMPLOYEE_SALARY: (state, data) => {
            state.employeeSalaries.data.push(data)
        },
        SET_EMPLOYEE_SALARY_LOADING: (state, loading) => {
            state.employeeSalary.loading = loading
        },
        SET_EMPLOYEE_SALARY: (state, data) => {
            state.employeeSalary.data = data
        },
        UPDATE_EMPLOYEE_SALARY: (state, {id, data}) => {
            state.employeeSalaries.data[state.employeeSalaries.data.findIndex(employeeSalary => employeeSalary.id === id)] = data
        },
        REMOVE_EMPLOYEE_SALARY: (state, id) => {
            state.employeeSalaries.data = state.employeeSalaries.data.filter(data => data.id !== id)
        }
    }
}
