import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const companySettingUrl = 'setting/companySetting'

export default {
    namespaced: true,
    state: {
        companySetting: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {

        getCompanySetting({commit, state}) {
            if (!Object.keys(state.companySetting.data).length) {
                commit('SET_COMPANY_SETTING_LOADING', true)
                return axiosAdmin.get(`${companySettingUrl}`)
                    .then((res) => {
                        commit('SET_COMPANY_SETTING_LOADING', false)
                        commit('SET_COMPANY_SETTING', res.data.data)
                        return res
                    }).catch((err) => {
                        commit('SET_COMPANY_SETTING_LOADING', false)
                        showErrors(err)
                    });
            }
        },
        updateCompanySetting({commit}, {id, form}) {
            return axiosAdmin.post(`${companySettingUrl}/${id}`, form)
                .then((res) => {
                    commit('SET_COMPANY_SETTING', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        }
    },
    mutations: {
        SET_COMPANY_SETTING_LOADING: (state, loading) => {
            state.companySetting.loading = loading
        },
        SET_COMPANY_SETTING: (state, data) => {
            state.companySetting.data = data
        },

    }
}
