import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const clientGroupUrl = 'crm/clientGroup'

export default {
    namespaced: true,
    state: {
        clientGroups: {
            data: [],
            loading: false
        },
        clientGroup: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getClientGroupCode(){
            return axiosAdmin.get(`${clientGroupUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getClientGroups({state, commit}) {
            if (!state.clientGroups.data.length) {
                commit('SET_CLIENT_GROUPS_LOADING', true)
                return axiosAdmin.get(`${clientGroupUrl}`)
                    .then((res) => {
                        commit("SET_CLIENT_GROUPS_LOADING", false);
                        commit('SET_CLIENT_GROUPS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_CLIENT_GROUPS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeClientGroup({commit}, form) {
            return axiosAdmin.post(`${clientGroupUrl}`, form)
                .then((res) => {
                    commit('ADD_CLIENT_GROUP', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getClientGroup({commit}, id) {
            commit('SET_CLIENT_GROUP_LOADING', true)
            return axiosAdmin.get(`${clientGroupUrl}/${id}`)
                .then((res) => {
                    commit('SET_CLIENT_GROUP_LOADING', false)
                    commit('SET_CLIENT_GROUP', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_CLIENT_GROUP_LOADING', false)
                    showErrors(err)
                });
        },
        updateClientGroup({commit}, {id, form}) {
            return axiosAdmin.put(`${clientGroupUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_CLIENT_GROUP', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteClientGroup({commit}, id) {
            return axiosAdmin.delete(`${clientGroupUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_CLIENT_GROUP', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_CLIENT_GROUPS_LOADING: (state, loading) => {
            state.clientGroups.loading = loading
        },
        SET_CLIENT_GROUPS: (state, data) => {
            state.clientGroups.data = data
        },
        ADD_CLIENT_GROUP: (state, data) => {
            state.clientGroups.data.push(data)
        },
        SET_CLIENT_GROUP_LOADING: (state, loading) => {
            state.clientGroup.loading = loading
        },
        SET_CLIENT_GROUP: (state, data) => {
            state.clientGroup.data = data
        },
        UPDATE_CLIENT_GROUP: (state, {id, data}) => {
            state.clientGroups.data[state.clientGroups.data.findIndex(clientGroup => clientGroup.id === id)] = data
        },
        REMOVE_CLIENT_GROUP: (state, id) => {
            state.clientGroups.data = state.clientGroups.data.filter(data => data.id !== id)
        }
    }
}
