import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const ledgerGroupUrl = 'account/ledgerGroup'

export default {
    namespaced: true,
    state: {
        ledgerGroups: {
            data: [],
            loading: false
        },
        ledgerGroup: {
            data: {},
            loading: false
        }
    },
    getters: {
        ledgerSubGroups(state) {
            return state.ledgerGroups.data.filter(ledgerGroup => ledgerGroup.ledger_group_id)
        },
        mainLedgerGroups(state) {
            return state.ledgerGroups.data.filter(ledgerGroup => ledgerGroup.ledger_group_id === '')
        }
    },
    actions: {
        getLedgerGroups({state, commit}) {
            if (!state.ledgerGroups.data.length) {
                commit('SET_LEDGER_GROUPS_LOADING', true)
                return axiosAdmin.get(`${ledgerGroupUrl}`)
                    .then((res) => {
                        commit("SET_LEDGER_GROUPS_LOADING", false);
                        commit('SET_LEDGER_GROUPS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_LEDGER_GROUPS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeLedgerGroup({commit}, form) {
            return axiosAdmin.post(`${ledgerGroupUrl}`, form)
                .then((res) => {
                    commit('ADD_LEDGER_GROUP', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getLedgerGroup({commit}, id) {
            commit('SET_LEDGER_GROUP_LOADING', true)
            return axiosAdmin.get(`${ledgerGroupUrl}/${id}`)
                .then((res) => {
                    commit('SET_LEDGER_GROUP_LOADING', false)
                    commit('SET_LEDGER_GROUP', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_LEDGER_GROUP_LOADING', false)
                    showErrors(err)
                });
        },
        updateLedgerGroup({commit}, {id, form}) {
            return axiosAdmin.put(`${ledgerGroupUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_LEDGER_GROUP', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },

        deleteLedgerGroup({commit}, id) {
            return axiosAdmin.delete(`${ledgerGroupUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_LEDGER_GROUP', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_LEDGER_GROUPS_LOADING: (state, loading) => {
            state.ledgerGroups.loading = loading
        },
        SET_LEDGER_GROUPS: (state, data) => {
            state.ledgerGroups.data = data
        },
        ADD_LEDGER_GROUP: (state, data) => {
            state.ledgerGroups.data.push(data)
        },
        SET_LEDGER_GROUP_LOADING: (state, loading) => {
            state.ledgerGroup.loading = loading
        },
        SET_LEDGER_GROUP: (state, data) => {
            state.ledgerGroup.data = data
        },
        UPDATE_LEDGER_GROUP: (state, {id, data}) => {
            state.ledgerGroups.data[state.ledgerGroups.data.findIndex(ledgerGroup => ledgerGroup.id === id)] = data
        },

        REMOVE_LEDGER_GROUP: (state, id) => {
            state.ledgerGroups.data = state.ledgerGroups.data.filter(data => data.id !== id)
        }
    }
}
