import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const salesReturnUrl = 'inventory/salesReturn'

export default {
    namespaced: true,
    state: {
        allSalesReturns: {
            data: [],
            loading: false
        },
        salesReturns: {
            data: [],
            meta: {},
            loading: false
        },
        salesReturn: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getSalesReturnCode() {
            return axiosAdmin.get(`${salesReturnUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getAllSalesReturns({commit}, {client_ledger_id = ''}) {
            commit('SET_ALL_SALES_RETURNS_LOADING', true)
            return axiosAdmin.get(`${salesReturnUrl}/all?client_ledger_id=${client_ledger_id}`)
                .then((res) => {
                    commit("SET_ALL_SALES_RETURNS_LOADING", false);
                    commit('SET_ALL_SALES_RETURNS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_SALES_RETURNS_LOADING", false);
                    showErrors(err)
                });
        },
        getSalesReturns({commit}, {
            page = 1,
            limit = '',
            search = '',
            client_ledger_id = '',
            from_date = '',
            to_date = ''
        }) {
            commit('SET_SALES_RETURNS_LOADING', true)
            return axiosAdmin.get(`${salesReturnUrl}?page=${page}&limit=${limit}&search=${search}&client_ledger_id=${client_ledger_id}&from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_SALES_RETURNS_LOADING", false);
                    commit('SET_SALES_RETURNS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_SALES_RETURNS_LOADING", false);
                    showErrors(err)
                });
        },
        storeSalesReturn({commit}, form) {
            return axiosAdmin.post(`${salesReturnUrl}`, form)
                .then((res) => {
                    commit('ADD_SALES_RETURN', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getSalesReturn({commit}, id) {
            commit('SET_SALES_RETURN_LOADING', true)
            return axiosAdmin.get(`${salesReturnUrl}/${id}`)
                .then((res) => {
                    commit('SET_SALES_RETURN_LOADING', false)
                    commit('SET_SALES_RETURN', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_SALES_RETURN_LOADING', false)
                    showErrors(err)
                });
        },
        deleteSalesReturn({commit}, id) {
            return axiosAdmin.delete(`${salesReturnUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_SALES_RETURN', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ALL_SALES_RETURNS_LOADING: (state, loading) => {
            state.allSalesReturns.loading = loading
        },
        SET_ALL_SALES_RETURNS: (state, data) => {
            state.allSalesReturns.data = data
        },
        SET_SALES_RETURNS_LOADING: (state, loading) => {
            state.salesReturns.loading = loading
        },
        SET_SALES_RETURNS: (state, data) => {
            state.salesReturns.data = data.data
            state.salesReturns.meta = data.meta
        },
        ADD_SALES_RETURN: (state, data) => {
            state.salesReturns.data.push(data)
        },
        SET_SALES_RETURN_LOADING: (state, loading) => {
            state.salesReturn.loading = loading
        },
        SET_SALES_RETURN: (state, data) => {
            state.salesReturn.data = data
        },
        REMOVE_SALES_RETURN: (state, id) => {
            state.salesReturns.data = state.salesReturns.data.filter(data => data.id !== id)
        }
    }
}
