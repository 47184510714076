import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const purchaseReturnUrl = 'inventory/purchaseReturn'

export default {
    namespaced: true,
    state: {
        allPurchaseReturns: {
            data: [],
            loading: false
        },
        purchaseReturns: {
            data: [],
            meta: {},
            loading: false
        },
        purchaseReturn: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getPurchaseReturnCode() {
            return axiosAdmin.get(`${purchaseReturnUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getAllPurchaseReturns({commit}, {from_date = '',to_date = '',supplier_ledger_id = ''
        }) {
            commit('SET_ALL_PURCHASE_RETURNS_LOADING', true)
            return axiosAdmin.get(`${purchaseReturnUrl}/all?from_date=${from_date}&to_date=${to_date}&supplier_ledger_id=${supplier_ledger_id}`)
                .then((res) => {
                    commit("SET_ALL_PURCHASE_RETURNS_LOADING", false);
                    commit('SET_ALL_PURCHASE_RETURNS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_PURCHASE_RETURNS_LOADING", false);
                    showErrors(err)
                });
        },
        getPurchaseReturns({commit}, {
            page = 1,
            limit = '',
            search = '',
            from_date = '',
            to_date = '',
            supplier_ledger_id = ''
        }) {
            commit('SET_PURCHASE_RETURNS_LOADING', true)
            return axiosAdmin.get(`${purchaseReturnUrl}?page=${page}&limit=${limit}&search=${search}&from_date=${from_date}&to_date=${to_date}&supplier_ledger_id=${supplier_ledger_id}`)
                .then((res) => {
                    commit("SET_PURCHASE_RETURNS_LOADING", false);
                    commit('SET_PURCHASE_RETURNS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_PURCHASE_RETURNS_LOADING", false);
                    showErrors(err)
                });
        },
        storePurchaseReturn({commit}, form) {
            return axiosAdmin.post(`${purchaseReturnUrl}`, form)
                .then((res) => {
                    commit('ADD_PURCHASE_RETURN', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getPurchaseReturn({commit}, id) {
            commit('SET_PURCHASE_RETURN_LOADING', true)
            return axiosAdmin.get(`${purchaseReturnUrl}/${id}`)
                .then((res) => {
                    commit('SET_PURCHASE_RETURN_LOADING', false)
                    commit('SET_PURCHASE_RETURN', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_PURCHASE_RETURN_LOADING', false)
                    showErrors(err)
                });
        },
        deletePurchaseReturn({commit}, id) {
            return axiosAdmin.delete(`${purchaseReturnUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_PURCHASE_RETURN', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_ALL_PURCHASE_RETURNS_LOADING: (state, loading) => {
            state.allPurchaseReturns.loading = loading
        },
        SET_ALL_PURCHASE_RETURNS: (state, data) => {
            state.allPurchaseReturns.data = data
        },
        SET_PURCHASE_RETURNS_LOADING: (state, loading) => {
            state.purchaseReturns.loading = loading
        },
        SET_PURCHASE_RETURNS: (state, data) => {
            state.purchaseReturns.data = data.data
            state.purchaseReturns.meta = data.meta
        },
        ADD_PURCHASE_RETURN: (state, data) => {
            state.purchaseReturns.data.push(data)
        },
        SET_PURCHASE_RETURN_LOADING: (state, loading) => {
            state.purchaseReturn.loading = loading
        },
        SET_PURCHASE_RETURN: (state, data) => {
            state.purchaseReturn.data = data
        },
        REMOVE_PURCHASE_RETURN: (state, id) => {
            state.purchaseReturns.data = state.purchaseReturns.data.filter(data => data.id !== id)
        }
    }
}
