import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const monthUrl = 'setting/month'

export default {
    namespaced: true,
    state: {
        months: {
            data: [],
            loading: false
        },
        month: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getMonths({state, commit}) {
            if (!state.months.data.length) {
                commit('SET_MONTHS_LOADING', true)
                return axiosAdmin.get(`${monthUrl}`)
                    .then((res) => {
                        commit("SET_MONTHS_LOADING", false);
                        commit('SET_MONTHS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_MONTHS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeMonth({commit}, form) {
            return axiosAdmin.post(`${monthUrl}`, form)
                .then((res) => {
                    commit('ADD_MONTH', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getMonth({commit}, id) {
            commit('SET_MONTH_LOADING', true)
            return axiosAdmin.get(`${monthUrl}/${id}`)
                .then((res) => {
                    commit('SET_MONTH_LOADING', false)
                    commit('SET_MONTH', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_MONTH_LOADING', false)
                    showErrors(err)
                });
        },
        updateMonth({commit}, {id, form}) {
            return axiosAdmin.put(`${monthUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_MONTH', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteMonth({commit}, id) {
            return axiosAdmin.delete(`${monthUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_MONTH', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_MONTHS_LOADING: (state, loading) => {
            state.months.loading = loading
        },
        SET_MONTHS: (state, data) => {
            state.months.data = data
        },
        ADD_MONTH: (state, data) => {
            state.months.data.push(data)
        },
        SET_MONTH_LOADING: (state, loading) => {
            state.month.loading = loading
        },
        SET_MONTH: (state, data) => {
            state.month.data = data
        },
        UPDATE_MONTH: (state, {id, data}) => {
            state.months.data[state.months.data.findIndex(month => month.id === id)] = data
        },
        REMOVE_MONTH: (state, id) => {
            state.months.data = state.months.data.filter(data => data.id !== id)
        }
    }
}
