import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const quotationUrl = 'inventory/quotation'

export default {
    namespaced: true,
    state: {
        allQuotations: {
            data: [],
            loading: false
        },
        quotations: {
            data: [],
            meta: {},
            loading: false
        },
        quotation: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getQuotationCode() {
            return axiosAdmin.get(`${quotationUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getAllQuotations({commit}, {client_ledger_id = ''}) {
            commit('SET_ALL_QUOTATIONS_LOADING', true)
            return axiosAdmin.get(`${quotationUrl}/all?client_ledger_id=${client_ledger_id}`)
                .then((res) => {
                    commit("SET_ALL_QUOTATIONS_LOADING", false);
                    commit('SET_ALL_QUOTATIONS', res.data.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_ALL_QUOTATIONS_LOADING", false);
                    showErrors(err)
                });
        },
        getQuotations({commit}, {
            page = 1,
            limit = '',
            search = '',
            client_ledger_id = '',
            from_date = '',
            to_date = ''
        }) {
            commit('SET_QUOTATIONS_LOADING', true)
            return axiosAdmin.get(`${quotationUrl}?page=${page}&limit=${limit}&search=${search}&client_ledger_id=${client_ledger_id}&from_date=${from_date}&to_date=${to_date}`)
                .then((res) => {
                    commit("SET_QUOTATIONS_LOADING", false);
                    commit('SET_QUOTATIONS', res.data)
                    return res
                })
                .catch((err) => {
                    commit("SET_QUOTATIONS_LOADING", false);
                    showErrors(err)
                });
        },
        storeQuotation({commit}, form) {
            return axiosAdmin.post(`${quotationUrl}`, form)
                .then((res) => {
                    commit('ADD_QUOTATION', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getQuotation({commit}, id) {
            commit('SET_QUOTATION_LOADING', true)
            return axiosAdmin.get(`${quotationUrl}/${id}`)
                .then((res) => {
                    commit('SET_QUOTATION_LOADING', false)
                    commit('SET_QUOTATION', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_QUOTATION_LOADING', false)
                    showErrors(err)
                });
        },
        deleteQuotation({commit}, id) {
            return axiosAdmin.delete(`${quotationUrl}`)
                .then((res) => {
                    commit('REMOVE_QUOTATION', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
        convertToSale({commit}, {id, form}) {
            return axiosAdmin.post(`${quotationUrl}/${id}/convertToSale`, form)
                .then((res) => {
                    commit('UPDATE_QUOTATION', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
    },
    mutations: {
        SET_ALL_QUOTATIONS_LOADING: (state, loading) => {
            state.allQuotations.loading = loading
        },
        SET_ALL_QUOTATIONS: (state, data) => {
            state.allQuotations.data = data
        },
        SET_QUOTATIONS_LOADING: (state, loading) => {
            state.quotations.loading = loading
        },
        SET_QUOTATIONS: (state, data) => {
            state.quotations.data = data.data
            state.quotations.meta = data.meta
        },
        ADD_QUOTATION: (state, data) => {
            state.quotations.data.push(data)
        },
        SET_QUOTATION_LOADING: (state, loading) => {
            state.quotation.loading = loading
        },
        SET_QUOTATION: (state, data) => {
            state.quotation.data = data
        },
        REMOVE_QUOTATION: (state, id) => {
            state.quotations.data = state.quotations.data.filter(data => data.id !== id)
        },
        UPDATE_QUOTATION: (state, {id, data}) => {
            state.quotations.data[state.quotations.data.findIndex(quotation => quotation.id === id)] = data
        },
    }
}
