import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const supplierUrl = 'crm/supplier'

export default {
    namespaced: true,
    state: {
        suppliers: {
            data: [],
            loading: false
        },
        supplier: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getSupplierCode(){
            return axiosAdmin.get(`${supplierUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getSuppliers({state, commit}) {
            if (!state.suppliers.data.length) {
                commit('SET_SUPPLIERS_LOADING', true)
                return axiosAdmin.get(`${supplierUrl}`)
                    .then((res) => {
                        commit("SET_SUPPLIERS_LOADING", false);
                        commit('SET_SUPPLIERS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_SUPPLIERS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeSupplier({commit}, form) {
            return axiosAdmin.post(`${supplierUrl}`, form)
                .then((res) => {
                    commit('ADD_SUPPLIER', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },

        // eslint-disable-next-line no-unused-vars
        importSupplier({commit}, data) {
            return axiosAdmin.post(`crm/importSupplier`, data)
                .then((res) => {
                    return res
                }).catch((err) => {
                    throw err
                })
        },

        getSupplier({commit}, id) {
            commit('SET_SUPPLIER_LOADING', true)
            return axiosAdmin.get(`${supplierUrl}/${id}`)
                .then((res) => {
                    commit('SET_SUPPLIER_LOADING', false)
                    commit('SET_SUPPLIER', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_SUPPLIER_LOADING', false)
                    showErrors(err)
                });
        },
        updateSupplier({commit}, {id, form}) {
            return axiosAdmin.post(`${supplierUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_SUPPLIER', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteSupplier({commit}, id) {
            return axiosAdmin.delete(`${supplierUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_SUPPLIER', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_SUPPLIERS_LOADING: (state, loading) => {
            state.suppliers.loading = loading
        },
        SET_SUPPLIERS: (state, data) => {
            state.suppliers.data = data
        },
        ADD_SUPPLIER: (state, data) => {
            state.suppliers.data.push(data)
        },
        SET_SUPPLIER_LOADING: (state, loading) => {
            state.supplier.loading = loading
        },
        SET_SUPPLIER: (state, data) => {
            state.supplier.data = data
        },
        UPDATE_SUPPLIER: (state, {id, data}) => {
            state.suppliers.data[state.suppliers.data.findIndex(supplier => supplier.id === id)] = data
        },
        REMOVE_SUPPLIER: (state, id) => {
            state.suppliers.data = state.suppliers.data.filter(data => data.id !== id)
        }
    }
}
