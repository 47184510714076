import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const leaveTypeUrl = 'hr/leaveType'

export default {
    namespaced: true,
    state: {
        leaveTypes: {
            data: [],
            loading: false
        },
        leaveType: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getLeaveTypes({state, commit}) {
            if (!state.leaveTypes.data.length) {
                commit('SET_LEAVE_TYPES_LOADING', true)
                return axiosAdmin.get(`${leaveTypeUrl}`)
                    .then((res) => {
                        commit("SET_LEAVE_TYPES_LOADING", false);
                        commit('SET_LEAVE_TYPES', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_LEAVE_TYPES_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeLeaveType({commit}, form) {
            return axiosAdmin.post(`${leaveTypeUrl}`, form)
                .then((res) => {
                    commit('ADD_LEAVE_TYPE', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getLeaveType({commit}, id) {
            commit('SET_LEAVE_TYPE_LOADING', true)
            return axiosAdmin.get(`${leaveTypeUrl}/${id}`)
                .then((res) => {
                    commit('SET_LEAVE_TYPE_LOADING', false)
                    commit('SET_LEAVE_TYPE', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_LEAVE_TYPE_LOADING', false)
                    showErrors(err)
                });
        },
        updateLeaveType({commit}, {id, form}) {
            return axiosAdmin.put(`${leaveTypeUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_LEAVE_TYPE', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteLeaveType({commit}, id) {
            return axiosAdmin.delete(`${leaveTypeUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_LEAVE_TYPE', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_LEAVE_TYPES_LOADING: (state, loading) => {
            state.leaveTypes.loading = loading
        },
        SET_LEAVE_TYPES: (state, data) => {
            state.leaveTypes.data = data
        },
        ADD_LEAVE_TYPE: (state, data) => {
            state.leaveTypes.data.push(data)
        },
        SET_LEAVE_TYPE_LOADING: (state, loading) => {
            state.leaveType.loading = loading
        },
        SET_LEAVE_TYPE: (state, data) => {
            state.leaveType.data = data
        },
        UPDATE_LEAVE_TYPE: (state, {id, data}) => {
            state.leaveTypes.data[state.leaveTypes.data.findIndex(leaveType => leaveType.id === id)] = data
        },
        REMOVE_LEAVE_TYPE: (state, id) => {
            state.leaveTypes.data = state.leaveTypes.data.filter(data => data.id !== id)
        }
    }
}
