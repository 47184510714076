import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const accountSettingUrl = 'setting/accountSetting'

export default {
    namespaced: true,
    state: {
        accountSetting: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {

        getAccountSetting({commit, state}) {
            if (!Object.keys(state.accountSetting.data).length) {
                commit('SET_ACCOUNT_SETTING_LOADING', true)
                return axiosAdmin.get(`${accountSettingUrl}`)
                    .then((res) => {
                        commit('SET_ACCOUNT_SETTING_LOADING', false)
                        commit('SET_ACCOUNT_SETTING', res.data.data)
                        return res
                    }).catch((err) => {
                        commit('SET_ACCOUNT_SETTING_LOADING', false)
                        showErrors(err)
                    });
            }
        },
        updateAccountSetting({commit}, {id, form}) {
            return axiosAdmin.put(`${accountSettingUrl}/${id}`, form)
                .then((res) => {
                    commit('SET_ACCOUNT_SETTING', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        }
    },
    mutations: {
        SET_ACCOUNT_SETTING_LOADING: (state, loading) => {
            state.accountSetting.loading = loading
        },
        SET_ACCOUNT_SETTING: (state, data) => {
            state.accountSetting.data = data
        },

    }
}
