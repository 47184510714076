import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const ledgerUrl = 'account/ledger'

export default {
    namespaced: true,
    state: {
        ledgers: {
            data: [],
            loading: false
        },
        ledger: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getLedgers({state, commit}) {
            if (!state.ledgers.data.length) {
                commit('SET_LEDGERS_LOADING', true)
                return axiosAdmin.get(`${ledgerUrl}`)
                    .then((res) => {
                        commit("SET_LEDGERS_LOADING", false);
                        commit('SET_LEDGERS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_LEDGERS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeLedger({commit}, form) {
            return axiosAdmin.post(`${ledgerUrl}`, form)
                .then((res) => {
                    commit('ADD_LEDGER', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getLedger({commit}, id) {
            commit('SET_LEDGER_LOADING', true)
            return axiosAdmin.get(`${ledgerUrl}/${id}`)
                .then((res) => {
                    commit('SET_LEDGER_LOADING', false)
                    commit('SET_LEDGER', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_LEDGER_LOADING', false)
                    showErrors(err)
                });
        },
        updateLedger({commit}, {id, form}) {
            return axiosAdmin.put(`${ledgerUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_LEDGER', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        updateStatus({commit}, id) {
            return axiosAdmin.put(`${ledgerUrl}/${id}/updateStatus`)
                .then((res) => {
                    commit('SET_STATUS', {id, data:res.data.status})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteLedger({commit}, id) {
            return axiosAdmin.delete(`${ledgerUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_LEDGER', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_LEDGERS_LOADING: (state, loading) => {
            state.ledgers.loading = loading
        },
        SET_LEDGERS: (state, data) => {
            state.ledgers.data = data
        },
        ADD_LEDGER: (state, data) => {
            state.ledgers.data.push(data)
        },
        SET_LEDGER_LOADING: (state, loading) => {
            state.ledger.loading = loading
        },
        SET_LEDGER: (state, data) => {
            state.ledger.data = data
        },
        UPDATE_LEDGER: (state, {id, data}) => {
            state.ledgers.data[state.ledgers.data.findIndex(ledger => ledger.id === id)] = data
        },
        SET_STATUS: (state, {id, status}) => {
            state.ledgers.data[state.ledgers.data.findIndex(ledger => ledger.id === id)].status = status
        },
        REMOVE_LEDGER: (state, id) => {
            state.ledgers.data = state.ledgers.data.filter(data => data.id !== id)
        }
    }
}
