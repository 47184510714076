<template>
  <router-view></router-view>
</template>
<script>
</script>

<style>
.btn{
  margin-left: 6px;
}
</style>
