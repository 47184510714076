import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const journalVoucherUrl = 'account/journalVoucher'

export default {
    namespaced: true,
    state: {
        journalVouchers: {
            data: [],
            loading: false
        },
        journalVoucher: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getJournalVoucherCode(){
            return axiosAdmin.get(`${journalVoucherUrl}/generate/code`)
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    throw err
                });
        },
        getJournalVouchers({state, commit}) {
            if (!state.journalVouchers.data.length) {
                commit('SET_JOURNAL_VOUCHERS_LOADING', true)
                return axiosAdmin.get(`${journalVoucherUrl}`)
                    .then((res) => {
                        commit("SET_JOURNAL_VOUCHERS_LOADING", false);
                        commit('SET_JOURNAL_VOUCHERS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_JOURNAL_VOUCHERS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeJournalVoucher({commit}, form) {
            return axiosAdmin.post(`${journalVoucherUrl}`, form)
                .then((res) => {
                    commit('ADD_JOURNAL_VOUCHER', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getJournalVoucher({commit}, id) {
            commit('SET_JOURNAL_VOUCHER_LOADING', true)
            return axiosAdmin.get(`${journalVoucherUrl}/${id}`)
                .then((res) => {
                    commit('SET_JOURNAL_VOUCHER_LOADING', false)
                    commit('SET_JOURNAL_VOUCHER', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_JOURNAL_VOUCHER_LOADING', false)
                    showErrors(err)
                });
        },
        updateJournalVoucher({commit}, {id, form}) {
            return axiosAdmin.put(`${journalVoucherUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_JOURNAL_VOUCHER', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteJournalVoucher({commit}, {id,cancelled_reason}) {
            return axiosAdmin.delete(`${journalVoucherUrl}/${id}?cancelled_reason=${cancelled_reason}`)
                .then((res) => {
                    commit('REMOVE_JOURNAL_VOUCHER', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_JOURNAL_VOUCHERS_LOADING: (state, loading) => {
            state.journalVouchers.loading = loading
        },
        SET_JOURNAL_VOUCHERS: (state, data) => {
            state.journalVouchers.data = data
        },
        ADD_JOURNAL_VOUCHER: (state, data) => {
            state.journalVouchers.data.push(data)
        },
        SET_JOURNAL_VOUCHER_LOADING: (state, loading) => {
            state.journalVoucher.loading = loading
        },
        SET_JOURNAL_VOUCHER: (state, data) => {
            state.journalVoucher.data = data
        },
        UPDATE_JOURNAL_VOUCHER: (state, {id, data}) => {
            state.journalVouchers.data[state.journalVouchers.data.findIndex(journalVoucher => journalVoucher.id === id)] = data
        },
        REMOVE_JOURNAL_VOUCHER: (state, id) => {
            state.journalVouchers.data = state.journalVouchers.data.filter(data => data.id !== id)
        }
    }
}
