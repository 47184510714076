import {axiosAdmin} from "@/helpers/axios";
import showErrors from "@/helpers/showErrors"

const designationUrl = 'hr/designation'

export default {
    namespaced: true,
    state: {
        designations: {
            data: [],
            loading: false
        },
        designation: {
            data: {},
            loading: false
        }
    },
    getters: {},
    actions: {
        getDesignations({state, commit}) {
            if (!state.designations.data.length) {
                commit('SET_DESIGNATIONS_LOADING', true)
                return axiosAdmin.get(`${designationUrl}`)
                    .then((res) => {
                        commit("SET_DESIGNATIONS_LOADING", false);
                        commit('SET_DESIGNATIONS', res.data.data)
                        return res
                    })
                    .catch((err) => {
                        commit("SET_DESIGNATIONS_LOADING", false);
                        showErrors(err)
                    });
            }
        },
        storeDesignation({commit}, form) {
            return axiosAdmin.post(`${designationUrl}`, form)
                .then((res) => {
                    commit('ADD_DESIGNATION', res.data.data)
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        getDesignation({commit}, id) {
            commit('SET_DESIGNATION_LOADING', true)
            return axiosAdmin.get(`${designationUrl}/${id}`)
                .then((res) => {
                    commit('SET_DESIGNATION_LOADING', false)
                    commit('SET_DESIGNATION', res.data.data)
                    return res
                }).catch((err) => {
                    commit('SET_DESIGNATION_LOADING', false)
                    showErrors(err)
                });
        },
        updateDesignation({commit}, {id, form}) {
            return axiosAdmin.put(`${designationUrl}/${id}`, form)
                .then((res) => {
                    commit('UPDATE_DESIGNATION', {id, data: res.data.data})
                    return res
                }).catch((err) => {
                    throw err
                })
        },
        deleteDesignation({commit}, id) {
            return axiosAdmin.delete(`${designationUrl}/${id}`)
                .then((res) => {
                    commit('REMOVE_DESIGNATION', id)
                    return res
                }).catch((err) => {
                    throw err;
                });
        },
    },
    mutations: {
        SET_DESIGNATIONS_LOADING: (state, loading) => {
            state.designations.loading = loading
        },
        SET_DESIGNATIONS: (state, data) => {
            state.designations.data = data
        },
        ADD_DESIGNATION: (state, data) => {
            state.designations.data.push(data)
        },
        SET_DESIGNATION_LOADING: (state, loading) => {
            state.designation.loading = loading
        },
        SET_DESIGNATION: (state, data) => {
            state.designation.data = data
        },
        UPDATE_DESIGNATION: (state, {id, data}) => {
            state.designations.data[state.designations.data.findIndex(designation => designation.id === id)] = data
        },
        REMOVE_DESIGNATION: (state, id) => {
            state.designations.data = state.designations.data.filter(data => data.id !== id)
        }
    }
}
